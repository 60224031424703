/* You can add global styles to this file, and also import other style files */
@use './search-layout.scss';

@use "lim-ui";
@use "lim-ui-animations";
@use "lim-ui-colors";
@use "lim-ui-modals";
@use "lim-ui-tooltips";
@use "lim-ui-list-items";
@use "lim-ui-pick-list";
@use "lim-ui-scrollbar";
@use "lim-ui-tables";
@use "lim-ui-mobile";
@use "lim-ui-inputs";

@import 'ngx-toastr/toastr';

@import 'tabulator-tables/dist/css/tabulator.min.css'; /* This is only used for the internal testing page */

@font-face {
  font-family: 'Clarity City';
  src: url('assets/fonts/ClarityCity-Thin.woff2') format('woff2');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Clarity City';
  src: url('assets/fonts/ClarityCity-LightItalic.woff2') format('woff2');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Clarity City';
  src: url('assets/fonts/ClarityCity-Black.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Clarity City';
  src: url('assets/fonts/ClarityCity-ExtraLightItalic.woff2') format('woff2');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Clarity City';
  src: url('assets/fonts/ClarityCity-ThinItalic.woff2') format('woff2');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Clarity City';
  src: url('assets/fonts/ClarityCity-BlackItalic.woff2') format('woff2');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Clarity City';
  src: url('assets/fonts/ClarityCity-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Clarity City';
  src: url('assets/fonts/ClarityCity-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Clarity City';
  src: url('assets/fonts/ClarityCity-MediumItalic.woff2') format('woff2');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Clarity City';
  src: url('assets/fonts/ClarityCity-SemiBoldItalic.woff2') format('woff2');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Clarity City';
  src: url('assets/fonts/ClarityCity-SemiBold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Clarity City';
  src: url('assets/fonts/ClarityCity-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Clarity City';
  src: url('assets/fonts/ClarityCity-BoldItalic.woff2') format('woff2');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Clarity City';
  src: url('assets/fonts/ClarityCity-RegularItalic.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Clarity City';
  src: url('assets/fonts/ClarityCity-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Clarity City';
  src: url('assets/fonts/ClarityCity-ExtraLight.woff2') format('woff2');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Clarity City';
  src: url('assets/fonts/ClarityCity-ExtraBoldItalic.woff2') format('woff2');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Clarity City';
  src: url('assets/fonts/ClarityCity-ExtraBold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

* {
  font-family: 'Clarity City', sans-serif;
  font-style: normal;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

html,
body {
  padding: 0px;
  margin: 0px;
  height: 100%;
  line-height: 1.3;
  color: lim-ui.$colors-grey;
  //height: 100vh;
}

body {
  background-color: lim-ui.$colors-white;
  color: lim-ui.$colors-grey;
}

/* Removes blue background color that chrome adds to inputs if they are auto-filled */
input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

/* Toastr Override Styles */
.toast-success {
  background-color: lim-ui.$colors-limble-green20;
  background-image: none;
}

.toast-error {
  background-color: lim-ui.$colors-limble-red20;
  background-image: none;
}

.toast-container.toast-top-center .ngx-toastr {
  max-width: 500px;
  min-width: 300px;
  width: auto;
}

.toast-container .ngx-toastr {
  color: lim-ui.$colors-limble-navy-blue;
  top: 30px;
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.2);
  padding-left: 15px;
}

.toast-container .ngx-toastr:hover {
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.2);
}

.toast-close-button {
  color: lim-ui.$colors-limble-navy-blue;
  font-weight: 100;
  font-size: 36px;
  height: 10px;
  right: 0px;
  top: -10px;
  text-shadow: none;
}

.toast-message {
  font-size: 14px;
}

.toast-message > a,
.toast-message > a:hover {
  color: #5083d5;
  font-family: 'Clarity City';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  text-decoration-line: underline;
}

// /* LIM UI Overrides */
lim-ui-modal {
  @include search-layout.search-for-phone-only {
    height: 100% !important;
    display: block;
  }
}
/* Checkbox label override */
@include search-layout.search-for-phone-only {
  .label {
    font-size: 14px !important;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}

/* Label UI used on various item results */
.label-container {
  position: relative;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 5px;
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: stretch;
}

.label-container button.selected {
  background-color: blue;
  color: white;
}

.label-container button.selected img {
  filter: invert(1);
}
