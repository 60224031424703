@mixin search-for-phone-only {
  @media (max-width: 480px) {
    @content;
  }

  //accounts for mobile devices in landscape mode
  @media (max-height: 480px) and (orientation: landscape) {
    @content;
  }
}
